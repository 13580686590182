import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./headers";
import Whatapp from "../cmp/whatapp";
import Footer from "./Footer";
import "../cmp/style.css";

const Services = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center mb-4">
        <div className="">
          <h1 className="text-white display-3 fw-semibold">Services</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container py-5">
        <h5 className="text-center fs-4" style={{ color: "#A06E0E" }}>
          Our Services
        </h5>
        <h1 className="text-center fw-bold mb-5">The Best Service For You</h1>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="shadow p-3 py-5">
              <div className="d-flex justify-content-around mb-3">
                <div className="cor-img">
                  <img src="fea-1.svg" />
                </div>
                <h3 className="text-center">
                  Building <br />
                  Contruction
                </h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  01
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: "justify"}}>
                We ensure compliance with building codes, safety regulations,
                and quality standards while adhering to project.
              </p>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="shadow p-3 py-5">
              <div className="d-flex justify-content-around mb-3">
                <div className="cor-img">
                  <img src="planning123.png" width={60}/>
                </div>
                <h3 className="text-center">
                Apartment
                  <br /> Contruction
                </h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  02
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: "justify"}}> 
                Construction services also encompass renovating and existing
                structures to update them change layout.
              </p>
            </div>
          </div>

          {/* <div className='col-md-4 mb-4'>
                         <div className='shadow p-3 py-4'>
                           
                           <div className='d-flex justify-content-around mb-3'> 
                           <div className='cor-img'><img src='ser-3.svg' /></div>
                           <h3 className='text-center'>House <br /> Renovation</h3>
                            <p className='fs-4' style={{color:'#B2B2B2'}}>03</p>
                           
                            </div>
                            <p className='px-4 fs-5'>
                            This includes building homes, apartments, and housing units. It can involve single-family homes and more.
                                </p>
                         </div>

                     </div> */}

          {/* <div className='col-md-4'>
                         <div className='shadow p-3 py-5'>
                           
                           <div className='d-flex justify-content-around mb-3'> 
                           <div className='cor-img'><img src='ser-4.svg' /></div>
                           <h3 className='text-center'>Architecture<br /> Design</h3>
                            <p className='fs-4' style={{color:'#B2B2B2'}}>04</p>
                           
                            </div>
                            <p className='px-4 fs-5'>
                            Construction services also encompass renovating and existing structures to update them change layout.
                                </p>
                         </div>

                     </div> */}

          {/* <div className="col-md-4">
            <div className="shadow p-3 py-5">
              <div className="d-flex justify-content-around mb-3 px-3">
                <div className="cor-img">
                  <img src="paint-roller.png" width={60} />
                </div>
                <h3 className="text-center">Painting & Waterproofing</h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  03
                </p>
              </div>
              <p className="px-4 fs-5">
                They ensure compliance with building codes, safety regulations,
                and quality standards while adhering to project.
              </p>
            </div>
          </div> */}

          <div className="col-md-4">
            <div className="shadow p-3 py-4">
              <div className="d-flex justify-content-around mb-3 px-3">
                <div className="cor-img">
                  <img src="planning.png" width={60} />
                </div>
                <h3 className="text-center">Irrigation Project</h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  03
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: "justify"}}>
                This includes building homes, apartments, and housing units. It
                can involve single-family homes and more.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="shadow p-3 py-4">
              <div className="d-flex justify-content-around mb-3 px-3">
                <div className="cor-img">
                  <img src="planning1.png" width={60} />
                </div>
                <h3 className="text-center">Buildings renovation</h3>
                <p className="fs-4" style={{ color: "#B2B2B2" }}>
                  04
                </p>
              </div>
              <p className="px-4 fs-5" style={{textAlign: "justify"}}>
                This includes building homes, apartments, and housing units. It
                can involve single-family homes and more.
              </p>
            </div>
          </div>
         

          
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Services;
