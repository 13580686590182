import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./headers";
import Whatapp from "../cmp/whatapp";
import Footer from "./Footer";
import "../cmp/style.css";
import "../cmp/style1.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Teams = ({ name, profession, imageSrc }) => {
  const [enlargedImage, setEnlargedImage] = useState(null);

  const showEnlarged = (imageSrc) => {
    setEnlargedImage(imageSrc);
  };

  const hideEnlarged = () => {
    setEnlargedImage(null);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);

  return (
    <>
      <Header />
      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center mb-4">
        <div className="">
          <h1 className="text-white display-3 fw-semibold"> Our Teams</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                {" "}
                Our Teams
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center mb-4" style={{ color: "#9D6B0E" }}>
          Our Teams
        </h2>
        <div className="row">
          {/* <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className=" position-relative">
                <img
                  src="team1.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="team2.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
                
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="team3.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
               
              </div>
            </div>
          </div> */}
          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="team4.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
               
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="team5.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
               
              </div>
            </div>
          </div>

          {/* <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100    position-relative">
                <img
                  src="plots.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
                
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots1.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-4 mb-4 team-member-card">
            <div class="card border-0 shadow-lg">
              <div className="w-100  position-relative">
                <img
                  src="plots12.jpg"
                  style={{ width: "100%", height: "356px" }}
                />
                
                
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Teams;

// const TeamMemberCard = ({ name, profession, imageSrc }) => {
//   return (
//     <div className='mb-4 team-member-card'>
//       <div className="card border-0 shadow-lg">
//         <div className='w-100 mt-3 '>
//           <img src={imageSrc} alt={`Team Member - ${name}`} style={{ height: '310px' }} />
//           <div className="overlay">
//             <h5>Name: <span className='fw-normal'>{name}</span></h5>
//             <h5>Profession: <span className='fw-normal'>{profession}</span></h5>
//           </div>
//         </div>
//         <div className="card-body text-center">
//           <h5>Name: <span className='fw-normal'>{name}</span></h5>
//           <h5>Profession: <span className='fw-normal'>{profession}</span></h5>
//         </div>
//       </div>
//     </div>
//   );
// };

// const TeamCarousel = () => {
//   return (
//     <OwlCarousel
//       items={4}
//       className="owl-theme"
//       loop
//       nav
//       margin={20}
//       responsive={responsiveOptions}
//     >
//       <TeamMemberCard name="Gourav Shukla" profession="Support Engineer" imageSrc="team-1.jpg" />
//       {/* Add more TeamMemberCard components for each team member */}
//     </OwlCarousel>
//   );
// };

// export default TeamCarousel;
