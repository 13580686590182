import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import About from './cmp/Aboutus'
import Home from './cmp/Home'
import Contact from './cmp/Contactus'
import Services from './cmp/Services'
import Projects from './cmp/Projects'
import Teams from './cmp/Teams'

function App() {
  return (
     <>
        <BrowserRouter>
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<About />}  />
        <Route path='/contactus' element={<Contact />} />
        <Route path='/services'  element={<Services />} />
        <Route path='/projects'  element={<Projects />} />
        <Route path='/teams'  element={<Teams />} />

        </Routes>
     
     
     </BrowserRouter>
     
     </>
  );
}

export default App;
