import Accordion from 'react-bootstrap/Accordion';
import './style.css'
function BasicExample() {
  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>What permits and approvals are needed for my project, and how do you handle them?</Accordion.Header>
        <Accordion.Body>
        We are  familiar with local regulations and obtain the necessary permits. They may also assist in the approval process for documents .
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How do you handle unexpected challenges or changes during construction?</Accordion.Header>
        <Accordion.Body>
        We  have a process for addressing changes in scope or unforeseen issues, including clear communication and a change order process.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How do you handle disputes or issues that may arise during the project?</Accordion.Header>
        <Accordion.Body>
        We have a dispute resolution process in their contract and be open to communication to address concerns promptly.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>What is your warranty and maintenance policy after project completion?</Accordion.Header>
        <Accordion.Body>
        We provide information on warranties for workmanship and materials and detail any maintenance requirements for the completed project.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="4">
        <Accordion.Header>
        How can I stay updated on the progress of the project?
        </Accordion.Header>
        <Accordion.Body>
        We have  a communication plan in place, including regular updates, progress meetings, and a point of contact for any questions
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default BasicExample;