import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Header from "./headers";
import {
  Nav,
  Navbar,
  Container,
  Dropdown,
  DropdownButton,
  Carousel,
  Accordion,
} from "react-bootstrap";
import Carouselslider from "./Carousal";
import Countdown from "./Count";
import Accordingcom from "./According";
import { Link } from "react-router-dom";
import "../cmp/style.css";
import Whatapp from "../cmp/whatapp";
import Phone from "../cmp/phone";
import { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "./Footer";
const Home = () => {
  const form = useRef();
  const [message, setMessage] = useState(""); // State for success/error message

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7jccguj",
        "template_2mgh76u",
        form.current,
        "MNcgvN8OLz3NXjtBM"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage("Message sent successfully");
          resetForm();
        },
        (error) => {
          console.log(error.text);
          setMessage("Message sending failed");
        }
      );
  };
  const resetForm = () => {
    form.current.reset(); // Reset the form
    // Show success message for 5 seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  // Use useEffect to clear the message state after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  const responsiveOptions = {
    0: {
      items: 1, // Show 1 item on screens smaller than 600px
    },
    600: {
      items: 2, // Show 2 items on screens between 600px and 1000px
    },
    1000: {
      items: 3, // Show 3 items on screens larger than 1000px
    },
  };

  return (
    <>
      <Header />

      <div className="container-fluid px-0">
        <Carousel data-bs-theme="dark">
          <Carousel.Item interval={3000}>
            <div className="bg-box">
              <img
                className="d-block w-100 img-res bg-img"
                src="bg-1.jpg"
                alt="First slide"
              />

              <Carousel.Caption className="slider-1">
                {/* <h1 className='display-3 fw-bold' data-aos="fade-right" data-aos-duration="2000" style={{color:'#5918B0'}}>Get Loan With Easy Steps</h1>
                                <p className='fs-5 fw-bold res-hide' style={{color:'black'}} data-aos="fade-left" data-aos-duration="2000">you can get any kind of loans like personal loan, business loan , home loan etc. <br />with easy process and assistance</p>
                                 <button className='btn btn-info px-4 fs-5' data-aos="fade-up"><a  className='text-decoration-none text-white' href='services' target='_blank'>Read More</a></button> */}
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item interval={3000}>
                        <img
                            className="d-block w-100 img-res"
                            src="bg-2.jpg"
                            alt="Second slide"
                        />
                       <Carousel.Caption className='slider-1'>
                                <h1 className='display-3 fw-bold' style={{color:'#5918B0'}} data-aos="fade-right" data-aos-duration="2000" >Get Loan With Easy Steps</h1>
                                <p className='fs-5 fw-bold  res-hide' style={{color:'black'}} data-aos="fade-left" data-aos-duration="2000">you can get any kind of loans like personal loan, business loan , home loan etc. <br />with easy process and assistance</p>
                                 <button className='btn btn-info px-4 fs-5' data-aos="fade-up"><a  className='text-decoration-none text-white' href='#'>Read More</a></button>
                            </Carousel.Caption>
                            </Carousel.Item> */}
          {/* <Carousel.Item interval={2000}>
                            <img
                                className="d-block w-100"
                                src="../Assets/Banner-3.png"
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h5>Third slide label</h5>
                                <p>
                                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item> */}
        </Carousel>
      </div>

      <div className="container fes-box bg-white py-4  shadow">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex first">
              <div>
                <img src="fea-1.svg" alr="" className="px-3 svg-image" />
              </div>
              <div className="auto-box">
                <h2>Building Contruction</h2>
                <p>
                  We ensure compliance with building codes, safety
                  regulations, and quality standards while adhering to project.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex first">
              <div>
                <img src="fea-2.svg" alr="" className="px-3 svg-image" />
              </div>
              <div>
                <h2>Gov Development & Irrigation Project</h2>
                <p>
                  Construction services also encompass renovating and existing
                  structures to update them change layout
                </p>
              </div>
            </div>
          </div>

          {/* <div className='col-md-4'>
                        <div className='d-flex first'>
                            <div>
                                <img src='fea-3.svg' alr='' className='px-3 svg-image' />
                            </div>
                            <div>
                                <h2>House
Renovation</h2>
                                <p>
                                This includes building homes, apartments, and housing units. It can involve single-family homes and more.
                                </p>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      <div className="container">
        {/* <h2 className="text-center">Our Services</h2> */}
        <h3 className="text-center display-5 fw-bold">
        NAPPL Construction Private Ltd
        </h3>
      </div>

      <div className="bg-light py-5">
        <div className="container mb-5">
          <div className="row">
            <div className="col-md-6">
              <img src="about1.jpg" style={{ width: "100%" , height:"500px"}} />
            </div>
            <div className="col-md-6">
            
              <h2 className="display-7 fw-bold" >
              Building/Apartment Construction and Gov Development Projects
              </h2>
              <p style={{textAlign: "justify"}}>
              NAPPL Construction Private Ltd is committed to creating a positive impact on society by building a sustainable future through its construction and development projects. The company aims to be a catalyst for positive change, emphasizing eco-friendly practices, social responsibility, and inclusive development.
              </p>
              <div className="d-flex mb-3">
                <div
                  style={{
                    width: "80px",
                    backgroundColor: "#F6E5E0",
                    height: "80px",
                    borderRadius: "5px",
                  }}
                  className=" d-flex justify-content-center p-3 me-4"
                >
                  <img src="mission.svg" />
                </div>
                <div>
                  <h4>Our Mission</h4>
                  <p style={{textAlign: "justify"}}>
                    Advanced maintain focused internal or organic sources rather
                    than vertical alignments.
                  </p>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div
                  style={{
                    width: "88px",
                    backgroundColor: "#F6E5E0",
                    height: "80px",
                    borderRadius: "5px",
                  }}
                  className=" d-flex justify-content-center p-3 me-4"
                >
                  <img src="certi.svg" className="img-vision" />
                </div>
                <div>
                  <h4>Our Vision</h4>
                  <p style={{textAlign: "justify"}}>
                  To be a pioneer in sustainable construction, providing eco-friendly and affordable housing solutions, contributing to the overall development and well-being of both rural and urban communities.
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center about-more">
                <div className="me-5 about-box">
                  <button className="btn btn-info text-white px-4 py-3 fs-5 mb-5">
                    <Link
                      to="/about-us"
                      className="text-decoration-none text-white"
                    >
                      About More
                    </Link>
                  </button>
                </div>
                <div className="d-flex">
                  <Phone />
                  <p className="fw-bold ms-3">+91 9470713257,<br></br> +91 9153882151</p>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    
      <div className="bg-white py-5" >
        <div className="container mb-5" >
          <div className="row">
            <div className="col-md-6">
              <p>Directors:</p>
              <h4 className=" fw-bold">
              Prabhakar Kumar:
              </h4>
              <p class="" style={{textAlign: "justify"}}>
              With a wealth of experience in construction and project management, Prabhakar Kumar brings leadership and strategic vision to NAPPL Construction Pvt Ltd. His commitment to sustainable development and social responsibility drives the company's ethos.
              </p>
              <br></br>
              <h4 className=" fw-bold">
              Diwakar Kumar:
              </h4>
              <p style={{textAlign: "justify"}}>
              As a co-director, Diwakar Kumar contributes his expertise in urban planning and infrastructure development. His focus on innovative solutions for urban challenges aligns with the company's mission to provide holistic urbanization solutions.
              </p>
            </div>
            <div className="col-md-6">
              <img src="team5 (2).jpg" style={{ width: "100%",}} />
            </div>
          </div>
        </div>
      </div>

    <div className="bg-light">
      <div className="container py-5">
        <div className="row">
        <div className="col-md-6">
            <img src="faq.png" style={{ width: "100%" }} />
          </div>
          <div className="col-md-6">
            {/* <h4>FREQUENTLY ASK QUESTIONS</h4> */}
            <h2 className="display-4 mb-4 fw-bold">
              Frequently Asked Questions
            </h2>
            <div>
              <Accordingcom />
            </div>
          </div>
         
        </div>
      </div>
      </div>

      <div className="contact-bg py-5">
        <div
          className="bg-white w-50 h-75 mob-res"
          style={{ position: "absolute", right: "20%" }}
        >
          <div className="row">
            <div className="col-md-4 ">
              <div
                className="px-3 py-4 quote-box"
                style={{
                  backgroundColor: "#E85B2A",
                  width: "100%",
                  height: "95.7%",
                }}
              >
                <h3 className="text-white">
                  You Get Free Consultation For Any Help
                </h3>
              </div>
            </div>
            <div className="col-md-8 py-4 ">
              <div className="py-3">
                <h3 className="text-center mb-4">Request A Quote</h3>
                <div>
                  {message && <div className="success-message">{message}</div>}
                  <form ref={form} onSubmit={sendEmail} autoComplete="off">
                    <div className="row px-3">
                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            name="to_name"
                            required
                            placeholder="name"
                          />
                          <label for="floatingInput">Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="from_name"
                            required
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">Email Id</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="tel"
                            //  <!-- Use type="tel" for telephone numbers -->
                            class="form-control"
                            id="floatingInput"
                            placeholder="name"
                            name="user_mobile"
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            required
                          />
                          <label for="floatingInput">Mobile No.</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            name="user_city"
                            required
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">City</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="form-floating mb-3">
                          <textarea
                            class="form-control"
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            name="message"
                            style={{ height: "130px" }}
                          ></textarea>
                          <label for="floatingTextarea2">Comments</label>
                        </div>
                      </div>

                      <div className="col-md-12 text-center">
                        <button type="submit" class="btn btn-primary">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div >
          {/* <h2 className="text-white text-center display-5 fw-bold mb-5">
            What Our Clients Say?
          </h2> */}
          
            {/* <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-2.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5" >
                    "NAPPL Construction Private Ltd is one of the top builders and construction companies in Ranchi. "
                  </p>

                  <h5 class="card-title text-black">Mahesh</h5>
                </div>
              </div>
            </div> */}

            {/* <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-3.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I am extremely satisfied with the civil contractor who
                    managed the construction of my commercial complex, including
                    the installation of an elevator."
                  </p>

                  <h5 class="card-title text-black">Romika jain</h5>
                </div>
              </div>
            </div> */}

            {/* <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-1.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I am thrilled with the civil contractor I hired to build my
                    home in Police Layout, Mysore. The entire construction
                    process, from start to finish, went smoothly and
                    efficiently."
                  </p>

                  <h5 class="card-title text-black">Rohit jaisawal</h5>
                </div>
              </div>
            </div> */}

            {/* <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-3.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I recently hired Nawal Agro and Plantation pvt ltd to renovate my
                    10-year-old bathroom and terrace, and I was really happy
                    with the results."
                  </p>

                  <h5 class="card-title text-black">Romika jain</h5>
                </div>
              </div>
            </div> */}

            {/* <div className=" mb-4">
              <div class="card border-0 shadow-lg">
                <div className="w-25 text-center rounded-circle mx-auto mt-3">
                  <img src="test-2.jpg" className="rounded-circle" />
                </div>
                <div class="card-body text-center">
                  <p class="card-text fs-5">
                    "I am incredibly satisfied with Nawal Agro and Plantation pvt ltd who
                    took on the painting and waterproofing of my apartment in MS
                    Palya."
                  </p>

                  <h5 class="card-title text-black">Mary Cruzleen</h5>
                </div>
              </div>
            </div> */}
         
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Home;
