import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./headers";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Whatapp from "../cmp/whatapp";
import "../cmp/style.css";
const Aboutus = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts or pathname changes
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="container-fluid home-brige d-flex flex-column align-items-center justify-content-center">
        <div className="">
          <h1 className="text-white display-3 fw-semibold">About Us</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item text-white ">
                <a href="#" className="text-white fs-5">
                  Home
                </a>
              </li>
              <li
                class="breadcrumb-item active text-white fs-5"
                aria-current="page"
              >
                About us
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div>
            <h1 className="mb-5 fw-bold text-center " style={{ color: "red" }}>
              Welcome to NAPPL Construction Private Ltd
            </h1>
          </div>
          <div className="col-md-5">
            <img src="ab.jpg" alt="pic" style={{ width: "100%", height:"450px" }} />
          </div>

          <div className="col-md-7">
            <br></br>
            <br></br>
            <h3 className="fw-bold">
            Emphasizing  Eco-friendly Development
            </h3>
            <br></br>
            <br></br>
            <br></br>
            <p className="fs-4" style={{textAlign: "justify"}}>
            NAPPL Construction Private Ltd is committed to creating a positive impact on society by building a sustainable future through its construction and development projects. The company aims to be a catalyst for positive change, emphasizing eco-friendly practices, social responsibility, and inclusive development.
            </p>
            <br></br>
            <br></br>
            
          </div>
        </div>
      </div>
      
      <div className="container py-2">
        <div className="row">
        <div className="d-flex mb-3 fs-5">
               <div>
               <h2 className="text-center" >Our Vision</h2>
                  <ul class="a">
                  <li style={{textAlign: "justify"}}>To be a pioneer in sustainable construction, providing eco-friendly and affordable housing solutions, contributing to the overall development and well-being of both rural and urban communities.</li>
                  
                  </ul>
                  <h2 className="text-center" >Our Mission</h2>
                  <ul class="a">
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px" , textAlign: "justify" }} >Eco-Friendly Low-Cost Housing: </strong>To design and construct innovative, environmentally sustainable, and cost-effective housing solutions that cater to the needs of rural and urban populations, fostering inclusive and sustainable living.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px", textAlign: "justify" }} >Government Housing and Construction Projects: </strong>To actively participate in government housing and construction projects, leveraging our expertise to contribute to the development of robust infrastructure and meeting the growing demand for quality housing across the nation.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px", textAlign: "justify" }} >Urbanization Solutions:</strong>To address challenges arising from urbanization, including solid waste management, renewable energy, water treatment, and safe drinking water. Our mission is to develop comprehensive solutions that promote smart and sustainable urban development practices.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px" , textAlign: "justify"}} >Minor Irrigation and Solar-Based Irrigation:</strong>To undertake government projects related to minor irrigation and solar-based irrigation, ensuring efficient water resource management and promoting sustainable agricultural practices for enhanced rural livelihoods.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px", textAlign: "justify" }} >Pan India Operation:</strong>To establish a strong and widespread presence across India, delivering high-quality construction and infrastructure solutions that align with regional needs while maintaining consistency in our commitment to sustainability and affordability.</li>

                  </ul>
                 
            <h2 className="text-center" >Core Values</h2>
            <ul class="a">
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px" }} >Sustainability </strong>Commitment to environmentally conscious and sustainable construction practices.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px" }} >Affordability: </strong>Providing cost-effective housing solutions without compromising quality.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px" }} >Innovation: </strong>Embracing innovative technologies and practices in construction and infrastructure development.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px" }} >Social Responsibility: </strong>Contributing to community welfare and addressing societal challenges through responsible business practices.</li>
                  <li style={{textAlign: "justify"}}><strong style={{textTransform: "uppercase",textIndent: "50px" }} >Integrity: </strong>Upholding the highest standards of integrity, ethics, and transparency in all operations.</li>
            </ul>
            <h2 className="text-center" >Key Objectives</h2>
            <ul class="a">
                  <li style={{textAlign: "justify"}}>To become a leading name in the construction industry, recognized for sustainable and affordable construction solutions.</li>
                  <li style={{textAlign: "justify"}}>To secure and successfully execute government housing, irrigation and construction projects across India.</li>
                  <li style={{textAlign: "justify"}}>To develop and implement solutions addressing challenges in urbanization, including solid waste management, renewable energy, water treatment, and safe drinking water.</li>
                  <li style={{textAlign: "justify"}}>To actively participate in government projects related to minor irrigation and solar-based irrigation, promoting sustainable agriculture.</li>
                  <li style={{textAlign: "justify"}}>To establish a strong and nationwide operational presence, contributing to the development of infrastructure and housing solutions tailored to regional needs.</li>
            </ul>
                </div>
        </div>
              
          
          
          
        </div>
      </div>
      <Whatapp />
      <Footer />
    </>
  );
};

export default Aboutus;
